import { differenceInSeconds } from 'date-fns';

export function diff2Date(dateLeft: string, dateRight: string) {
  const diff = differenceInSeconds(
    new Date(dateLeft.replace(/-/g, '/')),
    new Date(dateRight),
  );

  if (!isNaN(diff)) {
    const hourMinuteSecond = new Date(diff * 1000).toISOString().substr(11, 8);
    const hourMinuteSecondArr = hourMinuteSecond.split(':');
    return {
      hours: +hourMinuteSecondArr[0],
      minutes: +hourMinuteSecondArr[1],
      seconds: +hourMinuteSecondArr[2],
      asSeconds: diff,
    };
  }
  return null;
}

export function getCurrentLongDate(date: string) {
  let month = (new Date().getMonth() + 1).toString();
  const year = new Date().getFullYear();
  if (date.length === 1) {
    date = '0' + date;
  }
  if (month.length === 1) {
    month = '0' + month.toString();
  }
  return `${year}-${month}-${date}`;
}
